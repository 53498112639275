export const routePaths = {
  commonLogin: '/login',
  owner: {
    notFound: '/bo/p/app/404',
    login: '/bo/login',
    loginCallback: '/bo/login-callback',
    dashboard: {
      overview: '/bo/p/app/dashboard',
      members: '/bo/p/app/dashboard/members',
      referral: '/bo/p/app/dashboard/referral',
      offers: '/bo/p/app/dashboard/offers',
      rewards: '/bo/p/app/dashboard/rewards',
      points: '/bo/p/app/dashboard/points',
      marketing: '/bo/p/app/dashboard/marketing',
    },
    protectedHome: '/bo/p',
    loyaltyOffers: {
      list: '/bo/p/app/loyalty-offers',
      createDashboard: '/bo/p/app/loyalty-offers/dashboard',
      specific: (offerId: string, forceSummary = false) => ({
        manage: `/bo/p/app/loyalty-offers/manage/${offerId}${forceSummary ? '?forceSummary=true' : ''}`,
        view: `/bo/p/app/loyalty-offers/view/${offerId}`,
        transactions: `/bo/p/app/loyalty-offers/view/${offerId}/transactions`,
      }),
    },
    eventsImport: '/bo/p/app/events-import',
    reports: {
      main: {
        root: '/bo/p/app/reports/main',
        transactions: '/bo/p/app/reports/main/transactions',
      },
      customerList: '/bo/p/app/reports/customer-list',
      customerSignIn: '/bo/p/app/reports/customer-sign-in',
      customerDetail: '/bo/p/app/reports/customer-detail',
      transactions: '/bo/p/app/reports/transactions',
      offer: '/bo/p/app/reports/offer-summary',
      reward: '/bo/p/app/reports/reward-summary',
    },
    rewards: '/bo/p/app/rewards',
    createRewards: '/bo/p/app/rewards/create',
    rewardsDetails: (rewardId: string) => `/bo/p/app/rewards/${rewardId}`,
    editRewards: (rewardId: string) => `/bo/p/app/rewards/${rewardId}/edit`,
    duplicateRewards: (rewardId: string) =>
      `/bo/p/app/rewards/${rewardId}/duplicate`,
    publishRewards: (rewardId: string) =>
      `/bo/p/app/rewards/${rewardId}/publish`,
    help: '/bo/p/app/help',
    settings: {
      features: '/bo/p/app/settings/features',
      notifications: '/bo/p/app/settings/notifications',
      apps: '/bo/p/app/settings/apps',
    },
    campaigns: '/bo/p/app/campaigns',
    automation: {
      root: '/bo/p/app/automation',
      create: '/bo/p/app/automation/create',
      edit: (id: string) => `/bo/p/app/automation/edit/${id}`,
      details: (id: string) => `/bo/p/app/automation/${id}`,
    },
    emailCampaigns: {
      root: '/bo/p/app/email-campaigns',
      create: '/bo/p/app/email-campaigns/create',
      edit: (id: string) => `/bo/p/app/email-campaigns/edit/${id}`,
      details: (id: string) => `/bo/p/app/email-campaigns/${id}`,
    },
    SMSCampaigns: {
      root: '/bo/p/app/sms-campaigns',
      create: '/bo/p/app/sms-campaigns/create',
      edit: (id: string) => `/bo/p/app/sms-campaigns/edit/${id}`,
      details: (id: string) => `/bo/p/app/sms-campaigns/${id}`,
    },
    account: '/bo/p/app/account',
    accountEdit: '/bo/p/app/account-edit',
    members: {
      root: '/bo/p/app/members',
      importList: '/bo/p/app/members/import-list',
    },

    tiers: '/bo/p/app/tiers',
    createTiers: '/bo/p/app/tiers/create',
    editTiers: '/bo/p/app/tiers/edit',
    referral: '/bo/p/app/referral',
    manageReferral: '/bo/p/app/referral/manage',
    memberProfile: (memberId: string) => ({
      root: `/bo/p/app/members/${memberId}/surpriseRewards`,
      recommendedOffers: `/bo/p/app/members/${memberId}/recommendedOffers`,
      rewards: `/bo/p/app/members/${memberId}/rewards`,
      surpriseRewards: `/bo/p/app/members/${memberId}/surpriseRewards`,
      claimedRewards: `/bo/p/app/members/${memberId}/claimedRewards`,
      transactions: `/bo/p/app/members/${memberId}/transactions`,
      tiers: `/bo/p/app/members/${memberId}/tiers`,
      claimedRewardDetails: (id: string) =>
        `/bo/p/app/members/${memberId}/claimedRewards/${id}`,
    }),
    customerModeProfile: (memberId: string) => ({
      root: `/bo/p/app/customer-mode/${memberId}/claimedRewards`,
      offers: `/bo/p/app/customer-mode/${memberId}/offers`,
      rewards: `/bo/p/app/customer-mode/${memberId}/rewards`,
      claimedRewards: `/bo/p/app/customer-mode/${memberId}/claimedRewards`,
      transactions: `/bo/p/app/customer-mode/${memberId}/transactions`,
    }),
    customerMode: '/bo/p/app/customer-mode',
    billingDetails: '/bo/p/app/billing-details',
    businessInfo: '/bo/p/app/business-info',
    editBusinessInfo: '/bo/p/app/business-info/edit',
    onboardingStep11: '/bo/p/onboarding/step1-1',
    onboardingStep12: '/bo/p/onboarding/step1-2',
    onboardingStep2: '/bo/p/onboarding/step2',
    onboardingStep3: '/bo/p/onboarding/step3',
  },
  customer: {
    login: '/c/login',
    notFound: '/c/p/app/main/404',
    dashboard: '/c/p/app/main/dashboard',
    offer: (businessId: string, entityId: string) => ({
      general: `/c/p/app/main/offers/${businessId}/${entityId}`,
      transactions: `/c/p/app/main/offers/${businessId}/${entityId}/transactions`,
    }),
    businesses: {
      list: '/c/p/app/main/businesses',
      specific: (businessId: string) => ({
        offers: `/c/p/app/main/businesses/specific/${businessId}/offers`,
        rewards: `/c/p/app/main/businesses/specific/${businessId}/rewards`,
        tiers: `/c/p/app/main/businesses/specific/${businessId}/tiers`,
        claimedRewards: `/c/p/app/main/businesses/specific/${businessId}/claimed-rewards`,
        transactions: `/c/p/app/main/businesses/specific/${businessId}/transactions`,
      }),
      rewardsDetails: (businessId: string, rewardId: string) =>
        `/c/p/app/main/businesses/${businessId}/rewards/${rewardId}`,
      claimedRewardsDetails: (businessId: string, rewardId: string) =>
        `/c/p/app/main/businesses/${businessId}/claimed-rewards/${rewardId}`,
    },
    protectedHome: '/c/p',
    onboarding: '/c/p/onboarding',
    details: {
      account: '/c/p/app/details/account',
      editAccount: '/c/p/app/details/account/edit',
      help: '/c/p/app/details/help',
      settings: '/c/p/app/details/settings',
      eventsImport: '/c/p/app/details/events-import',
    },
  },
};
